$(function() {
 // 以下為Swiper設定

  // 共用Auto 
  var auplayFun = {
    delay: 3500,
    //stopOnLastSlide: false,
    disableOnInteraction: false,
  }

  // 進場動畫
  var pageFun = function(){
    var percent = 0;
    var timer = setInterval(function () {
      $(".bar").css("width", percent + "%");
      percent += 1;

      if (percent > 100) {
        clearInterval(timer);
        
        // 載完loading就把className拿掉...
        $('.js-loadHide').removeClass('js-loadHide');
        $(".pageloading").addClass("compelet");

        setTimeout(function () {
          bannerSwiper.params.autoplay = auplayFun;
          bannerSwiper.autoplay.start();
        },0);
      }
    }, 0);
  }

  if($('.bannerSwiper').length > 0) {
    // 首頁Banner
    var bannerSwiper = new Swiper('.bannerSwiper .swiper-container', {
      autoplay: auplayFun,
      parallax: true,
      keyboard : true,
      virtualTranslate : true,
      
      on:{
        setTranslate: function(){
          this.$wrapperEl.transition('')
          TweenMax.to(this.$wrapperEl, 1.5, {x:this.translate, ease:Power4.easeOut})
        }
      },

      pagination: {
        el: '.bannerSwiper .swiper-pagination',
        clickable: true,
      },
    });
    bannerSwiper.autoplay.stop();

    // pageFun();
  }

  // js using jquery
  setInterval(function(){
    $('.bannerSwiper .swiper-slide .txtInfo .title, .bannerSwiper .swiper-slide .txtInfo .txt,  .bannerSwiper .swiper-slide .imgInfo').addClass('animate__animated');

    $('.bannerSwiper .swiper-slide-active .txtInfo .title').addClass('animate__fadeInDown');
    $('.bannerSwiper .swiper-slide-active .txtInfo .txt').addClass('animate__fadeInUp'); 
    $('.bannerSwiper .swiper-slide-active .imgInfo').addClass('animate__fadeInRight'); 

    $(".bannerSwiper .swiper-slide-next .txtInfo .title, .swiper-slide-prev .txtInfo .title").removeClass('animate__animated, animate__fadeInDown');
    $(".bannerSwiper .swiper-slide-next .txtInfo .txt, .swiper-slide-prev .txtInfo .txt").removeClass('animate__animated, animate__fadeInUp');
    $(".bannerSwiper .swiper-slide-next .imgInfo, .swiper-slide-prev .imgInfo").removeClass('animate__animated, animate__fadeInRight');
  });

  // 產品詳細
  var Prod_mySwiper = new Swiper(".Prod-photo-small .Prod_mySwiper", {
    // autoplay: auplayFun,
    autoplay:false,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 3,
        spaceBetween: 5,
      }
    }
  });

  var Prod_mySwiper2 = new Swiper(".Prod-photo .Prod_mySwiper2", {
    // autoplay: auplayFun,
    autoplay:false,
    speed: 1000,
    spaceBetween: 10,
    navigation: {
      nextEl: ".Prod-photo .swiper-button-next",
      prevEl: ".Prod-photo .swiper-button-prev",
    },
    thumbs: {
      swiper: Prod_mySwiper,
    },
  });

  // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });

  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  // 左側分類
  toggleFun('.js-toggle-btn','.js-toggleClick')

  // 產品側邊選單
  var $slidemenu = $(".js-toggle > a");
    $slidemenu.on('click',function(){
      // 點擊當前項目才打開
      var serToggle = $(this).closest('li').find(".first_menu");
      $(".first_menu").not(serToggle).removeClass('active');
      $slidemenu.not($(this)).removeClass('active');

      if($(this).hasClass('active')){
        $(this).removeClass('active');
        serToggle.removeClass('active');
      }else{
        $(this).addClass('active');
        serToggle.addClass('active');
      }
    });

  // 產品側邊選單-下一層
  var $slidemenuSecond = $(".first_menu_li > a");
    $slidemenuSecond.on('click',function(){
      // 點擊當前項目才打開
      var serToggleSecond = $(this).closest('li').find(".second_menu");
      $(".second_menu").not(serToggleSecond).removeClass('active');
      $slidemenuSecond.not($(this)).removeClass('active');

      if($(this).hasClass('active')){
        $(this).removeClass('active');
        serToggleSecond.removeClass('active');
      }else{
        $(this).addClass('active');
        serToggleSecond.addClass('active');
      }
    });
   
  // BS navbar-collapse show / hidden
  $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })

  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });

});

function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

function btnScroll($name){
  $($name).on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });
}

// 取得頁面寬度
function viewport() {
  var e = window,
      a = 'inner';
  if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
}

// Toggle Fun
function toggleFun(item,status){
  $(item).on('click',function() {
    $(status).toggleClass('js-open');
    return false;
  });
}

// 文字點點點
function checkEllipsis(name,len){
  $(name).each(function(i){
    if($(this).text().length>len){
        $(this).attr("title",$(this).text());
        let text=$(this).text().substring(0,len-1)+"...";
        $(this).text(text);
    }
  });
}
